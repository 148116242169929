import { BlogHero } from '../../components/blogHero/blogHero.comp';
import { BlogPosts } from '../../components/blogPosts/blogPosts.comp';
import { Layout } from '../../components/layout/layout.comp';
import { getDataFromDB } from '../../lib/mongodb';
import { IBlogPage } from '../../types';

export default function Blog({ allPosts }: { allPosts: IBlogPage[]; }) {
	return (
		<Layout
			showFooter={true}
			showHeader={true}
			title='Common Ninja’s Blog | Helpful, Useful & Informative Articles'
			description='Explore helpful, useful & informative articles about web design, web development, marketing, SEO, apps, widgets, plugins & much more!'
			url={`https://www.commoninja.com/blog`}

		>
			<BlogHero />
			<BlogPosts blogPosts={allPosts} />
		</Layout>
	);
}

export async function getStaticProps() {
	const allPosts = await getDataFromDB<IBlogPage>('blogs',
		{
			status: 'published',
		},
		{
			slug: 1,
			name: 1,
			excerpt: 1,
			coverImage: 1,
			author: 1,
			publishedDate: 1
		},
		{
			publishedDate: -1
		});

	return {
		props: {
			allPosts,
		}
	};
}

